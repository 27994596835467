/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Mardoto-medium;
  src: url('./assets/fonts/Mardoto-Medium.ttf');
}
@font-face {
  font-family: Mardoto-regular;
  src: url('./assets/fonts/Mardoto-Regular.woff');
}
@font-face {
  font-family: SFProText-Semibold;
  src: url('./assets/fonts/SFProText-Semibold.ttf');
}
@font-face {
  font-family: SFProText-Regular;
  src: url('./assets/fonts/SFProText-Regular.ttf');
}
@font-face {
  font-family: Mardoto-bold;
  src: url('./assets/fonts/Mardoto-Bold.ttf');
}
@font-face {
  font-family: Mardoto-light;
  src: url('./assets/fonts/Mardoto-Light.ttf');
}
@font-face {
  font-family: Roboto-medium;
  src: url('./assets/fonts/Mardoto-Light.ttf');
}
.container-page,.container-page-partner{
  width: 90%;
  margin: auto;
}
.navbar-nav {
  padding: 24px 48px 0px 48px;
}
.logo{
  width: 201px;
  height: 59px;
  object-fit: contain;
}


.lang > .dropdown-toggle::after{
  content: none;
}
.lang > .dropdown-menu.show{

  border: none !important;
}
.lang_flag {
  cursor: pointer;
  margin-top: 5px;
}
.box{
  width: 52px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(0, 16, 77, 0.15);
  background-color: #ffffff;
  text-align: center;
  padding: 5px;
}
.bg1{
  background-image: url('./assets/image/bg1.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
}
.bg2{
  background-image: url('./assets/image/MaskGroup63.svg');
  background-repeat: no-repeat;
  background-position-x: left;
  background-size: auto;
  padding-top: 12rem;
}
.bg2_1{
  background-image: url('./assets/image/Intersection1.svg');
  background-repeat: no-repeat;
  background-position-x: left;
  background-size: 25%;
}
.first_title{
  font-family: Mardoto-medium,serif;
  font-size: 33px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  color: #334680;
}
.first_text{
  opacity: 0.5;
  font-family: Mardoto-regular, serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81;
  letter-spacing: normal;
  text-align: left;
  color: #334680;
}

.mobile_image{
  height: 769.9px;
  object-fit: contain;
}
.image_1{
  height: 300px;
  object-fit: contain;
}
.pic_2{
  width: 365px;
  height: 745.4px;
  object-fit: contain;
}
.numbers{
  vertical-align: middle;
  opacity: 0.2;
  font-family: SFProText-Semibold, serif;
  font-size: 108px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #334680;
}
.mr-7{
  margin-right: 7rem;
}
.ml-7{
  margin-left: 7rem;
}
.bg_color{
  background: rgba(171, 179, 204, 0);
}
.for_margin_1{
  margin-top: 100px;
}

.for_margin_2{
  margin-top: 300px;
}

.bg_5{
  background-image: url('./assets/image/pic_5_1.png');
  background-repeat: no-repeat;
  background-position-x: -18rem;
  background-size:contain;
}
.contact{
  width: 600px;
  border-radius: 26px;
  box-shadow: 0px 10px 16px 0 rgba(76, 76, 141, 0.1);
  background-color: #ffffff;
}
.bg_6{
  height: 100%;
  background-image: url('./assets/image/pic_6_1.png');
  background-repeat: no-repeat;
  background-size:contain;
  background-position-x: 90rem;

}

.finish{
  font-family: Mardoto-bold, serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.17;
  letter-spacing: normal;
  text-align: center;
  color: #334680;
}
.speak{
  opacity: 0.7;
  font-family: Mardoto-light, serif;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.17;
  letter-spacing: normal;
  text-align: center;
  color: #334680;
}
.input{
  width: 100%;
  font-family: Mardoto-regular, serif;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 0px 10px 10px 0px;
}
.input::placeholder {
  font-family: Mardoto-regular, serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.18px;
  text-align: left;
  color: #334680;

}
.mobile-ml-5{
  margin-left: 3rem;
}
.input-p2::placeholder{
  opacity: 50%;
}
.input:focus{
  outline: none;
}
.submit-button{
  font-family: Roboto-medium, serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: 0.27px;
  text-align: left;
  color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(76, 76, 141, 0.17);
  background-color: #68bc1a;
  padding: 12px;
  float: right;
  border: none;
}
.client-submit-button{
  font-family: Roboto-medium, serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: 0.27px;
  text-align: left;
  color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(76, 76, 141, 0.17);
  background-color: #ffb755;
  padding: 12px;
  float: right;
  border: none;
}
.feedback{
  font-family: Mardoto-medium, serif;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #334680;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.phone{
  opacity: 0.5;
  font-family: Mardoto-regular, serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #334680;
}
.infoMail{
  opacity: 0.5;
  font-family: Mardoto-regular, serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #334680;
}
.policy{
  font-family: SFProText-Regular, serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07;
  letter-spacing: normal;
  text-align: left;
  color: #334680;
}
.copyright{
  opacity: 0.5;
  font-family: SFProText-Regular, serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07;
  letter-spacing: normal;
  color: #334680;
}
.qr{
  width: 100%;
  max-width: 300px;
}
.market{
  object-fit: contain;
  opacity: 0.8;
}
.p_1{
  margin-top: 10px;
  font-family: Mardoto-medium,serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: #334680;
}
.p_2{
  opacity: 0.5;
  font-family: Mardoto-regular, serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.14px;
  color: #334680;
}
.title{
  font-family: Mardoto-medium, serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 36px;
  color: #334680;

}
.description{
  opacity: 0.5;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #334680;
  margin-top: 1rem;

}
.m-pl-5{
  padding-left: 3rem;
}
.m-pr-5{
  padding-right: 3rem;
}
.m-mr-5{
  padding-right: 3rem;
}
margin-5{
  margin: 3rem;
}
.title_02{
  line-height: 1.5;
  margin-top: 6rem;
}
.partner_title_02{
  line-height: 1.5;
}
.description_02{
  font-family: Mardoto-medium,serif;

}
.title_03{
  line-height: 1.5;
}
.description_03{
  font-family: Mardoto-medium,serif;
}
.partner_title_04{
  line-height: 1.5;
}
.title_04{
  line-height: 1.19;
  margin-top: 2rem;
}

.description_04{
  margin-top: 3rem;
  font-family: Mardoto-medium,serif;
}
.partner_description_04 {
  font-family: Mardoto-medium,serif;
  width: 65%;
}
.partner_title_05{
  line-height: 1.19;
}
.partner_description_05 {
  font-family: Mardoto-medium,serif;

}
.title_05{
  line-height: 1.5;
}
.description_05{
  margin-top: 3rem;
  font-family: Mardoto-regular;
}
.partner_title_06{
  line-height: 1.5;
}
.partner_description_06 {
  font-family: Mardoto-medium,serif;

}
.col-t2-p{
  margin-top: -12rem;
}
.feed{
  padding: 3rem;
}
.last_row{
  padding: 3rem;
  padding-left: 0px;
}
ul{
  text-align: left;
}
.container-page-partner .description_04{
  margin-top: 0px;
}
.page06{
  margin-top: 3rem;
}
.client{
  background-image: url('./assets/image/Path3753.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 43%;
}
.cl_page02{
  background-image: url('./assets/image/MaskGroup63.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: auto;
}
.cl_page02_1{
  background-image: linear-gradient(to bottom, rgb(251, 251, 253) -16%, #fefefe14 124%);
}
.cl_page03{
  background-image: linear-gradient(to bottom, rgba(249, 239, 219, 0), rgba(249, 239, 219, 0.51) 50%, rgba(249, 239, 219, 0));
}
.cl_page04{
  background-image: linear-gradient(to bottom, rgba(246, 247, 251, 0), rgba(242, 244, 250, 0.75) 50%, rgba(246, 247, 251, 0.05));
}
.cl_page05{
  background-image: linear-gradient(to bottom, rgba(249, 239, 219, 0), rgba(249, 239, 219, 0.51) 50%, rgba(249, 239, 219, 0));
}
.cl_page06{
  background-image: linear-gradient(to bottom, rgba(246, 247, 251, 0), rgba(242, 244, 250, 0.75) 50%, rgb(246, 246, 250));
}
.image_content1{
  display: none;
}
.footer{
  margin-top: 2rem;
  box-shadow: 0px -7px 15px 0 rgba(61, 117, 220, 0.08);
}
.client_partner{
  border-radius: 20px;
  background-color: #68bc1a;
  color: white !important;
  font-family: Roboto-medium,serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: 0.27px;
  text-align: left;
  padding: 10px;
  margin-top: 2rem;
}
.client_partner:hover{
  text-decoration: none;
}
.page02{
  height: 900px;
}
.section03{
  background-image: linear-gradient(to bottom, rgb(252, 253, 254) 5%, rgb(252, 252, 254) 51%, rgb(252, 252, 254) 95%);
}
.section04{
  background-image: linear-gradient(to bottom, rgb(252, 252, 254) 5%, rgb(252, 252, 253) 51%, rgba(171, 179, 204, 0.01) 95%);
}
.section06{
  background-image: linear-gradient(to bottom, rgb(255, 255, 255) 5%, rgba(170, 179, 204, 0.08) 51%, rgb(246, 247, 249) 95%);
}
.section_contact{
  background-image: linear-gradient(to bottom, rgb(247, 247, 250) 5%, rgb(246, 247, 249) 51%, rgba(171, 179, 204, 0) 95%);
}
.number_item_center{
  display: flex;
  align-items: center;
}
.ml-18{
  margin-left: 18% !important;
}
.mr-18{
  margin-right: 18% !important;
}
.bg11 .mobile_image{
  margin-left: 5%;
}
@media screen and (min-width: 1400px) {
  .col-xxl-6{
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .contact{
    width: 600px;
  }
}
@media screen and (max-width: 1600px) {
  .contact{
    width: 550px;
  }
  .mobile_image{
    height: 650px;
  }
  .page02 .mr-7{
    margin-right: 4rem;
  }
  .bg11 .mobile_image {
    margin-left: -5%;
  }
  .finish,.speak{
    font-size: 32px;
  }
}
@media screen and (max-width: 1400px) {
  .finish,.speak{
    font-size: 36px;
  }
  .contact{
    width: 600px;
    margin:auto;
  }
  .col-xxl-6>img{
    display: none;
  }
  .ml-7{
    margin-left: 0px;
  }
  .page02 .mr-7{
    margin-right: 2rem;
  }
  .page03 .ml-7{
    margin-left: 7rem;
  }
  .client{
    background-size: 50%;
  }
  .bg2_1 {
    background-size: 33%;
  }
  .bg11 .mobile_image {
    margin-left: -18%;
  }
}
@media screen and (max-width: 1200px){
  .navbar .box{
    margin-right: 0px !important;
  }

  .image_content1{
    display: block;
    text-align: center;
  }
  .image_content{
    display: none;
  }
  .client {
    background-size: 70%;
  }
  .bg11{
    background-image: url('./assets/image/bg1.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    margin-top: 2rem;
  }
  .bg1{
    background-image: none;
  }
  .bg1 .container-page-partner {
    width: 95%;
    margin-left: 5%;

  }
  .bg11 .mobile_image{
    margin-left: 15%;
  }
  .mobile-ml-5{
    margin-left: 0rem;
  }
  .mobile_image {
    width: 50%;
    height: 100%;
  }
  .for_margin_1,.for_margin_2{
    margin-top: 10px;
  }
  .image_part{
    text-align: center;
  }
  .bg_6{
    background-image: none;
  }
  .bg_5{
    background-image: none;
  }
  .for_center{
    text-align: center;
  }
  .col8-p{
    margin-top: -12rem;
  }
  .title{
    margin-top: 12%;
  }
  .m-pl-5{
    padding-left: 0;
  }
  .m-pr-5{
    padding-right: 0;
  }
  .m-mr-5{
    padding-right: 0;
  }
  .page02 .mr-7 {
    margin-right: 5rem;
  }
  .description_04{
    width: 100% !important;
  }
  .description_05{
    margin-top: unset;
  }
  .image_part div{
    padding: 0px !important;
  }
  .image_part div img{
    margin: 0px !important;
    width: 100%;
  }
  .page05{
    margin-top: 3rem;
  }
  .bg2{
    background-size: contain;
  }
  .bg2_1 {
    background-image: linear-gradient(to bottom, rgba(171, 179, 204, 0) 5%, rgb(252, 253, 254) 51%, rgb(252, 253, 254) 95%);
    background-repeat: no-repeat;
    background-position-x: unset;
    background-size: unset;
  }
  .bg2_1 img{
    margin-left: 0px;
  }
  .page03{
    padding-left: 0px;
  }
  .page02{
    height: auto;
  }
  .image_center{
    text-align: center;
  }
  .section05{
    background-image: none;
  }
  .section05 .image_content1{
    text-align: unset;
  }
  .section05 .container-page-partner,.section06 .container-page-partner{
    width: 100%;
  }
  .child-container{
    width: 90%;
    margin: auto !important;
  }
  .cl_page06 .image_content1 .mobile_image{
    margin: 1rem 0;
  }

}
@media screen  and (max-width: 1050px){
  .mr-7{
    margin-right: 4rem;
  }
}
@media screen and (max-width: 991px) {
  .border_oraneg,.border_green{
    height: 250px;
  }
  .description{
    text-align: center;
  }
  .description_li{
    text-align: left;
  }
  .first_title,.first_text,.qr-div{
    text-align: center;
  }
  .first_text ul{
    text-align: left;
  }
  .page03 .ml-7 {
    margin-left: 4rem;
  }
  .item_center{
    display: flex;
    align-items: center;
  }
  .title{
    margin-top: unset;
  }
  .col8-p,.col-t2-p{
    margin-top: unset;
  }
  .market_div{
    text-align: center;
  }
  .page02 >div{
    text-align: center;
  }
  .client_partner_div {
    text-align: center;
  }
  .client_partner {
    padding: 10px 50px;
    font-size: 16px;
  }
  .client {
    background-size: 75%;
  }
  .partner_description_04{
    width: 100%;
  }

}
@media screen and (max-width: 900px) {
  .container-page{
    width: 90%;
  }
  .navbar-nav {
    padding-left: 0px !important;
  }
  .logo {
    width: 100%;
  }
  .page02 .mr-7 {
    margin-right: 2rem;
  }

}
@media screen and (max-width: 800px) {
  .contact{
    width: 100%;
  }
  .logo {
    width: 110px;
  }
  .mr-7 {
    margin-right: 2rem;
  }
  .speak,.finish{
    font-size: 24px;
  }
  .input::placeholder{
    font-size: 15px;
  }
  .client-submit-button,.client_partnerm,.submit-button{
    font-size: 15px;
  }
  .page03 .ml-7 {
    margin-left: 1rem;
  }

}
@media screen and (max-width: 670px) {
  .numbers {
    font-size: 75px;
  }
  .title{
    font-size: 25px;
  }
  .client {
    background-size: 77%;
  }
}
@media screen and (max-width: 576px) {
  .client_partner {
    padding: 10px 40px;
    font-size: 14px;
  }
  .numbers {
    font-size: 43px;
  }
  .border_oraneg,.border_green,.border_green{
    width: 2px;
    height: 100px;
  }
  .title{
    font-size: 20px;
  }
  .description_02{
    margin-top: 1rem;
  }
  .speak,.finish{
    font-size: 20px;
  }
  .input::placeholder{
    font-size: 12px;
  }
  .client-submit-button,.submit-button{
    font-size: 12px;
  }
  .last_row{
    padding: 0px;
  }
  .container-page-partner .last_row{
    padding: 0 1rem;
    margin: auto;
  }
  .client {
    background-size: 80%;
  }
}
@media screen and (max-width: 450px) {
  .mr-7{
    margin-right: 1.5rem;
  }
  .feed{
    padding: 0px;
  }
  .feedback{
    font-size: 22px;
  }
  .phone,.infoMail{
    font-size: 15px;
  }
  .speak,.finish{
    font-size: 16px;
  }
  .input::placeholder{
    font-size: 10px;
  }
  .last_row img{
    width: 24px;
  }
  .client-submit-button,.submit-button{
    font-size: 10px;
  }
  .question > .p-5{
    padding: 1rem!important;
  }
  .page04 .mr-7 {
    margin-right: 0.5rem;
  }
  .page02 .mr-7 {
    margin-right: 1rem;
  }
  .client {
    background-size: 85%;
  }
}
@media screen and (max-width: 400px) {
  .client_partner {
    font-size: 12px;
  }
  .logo {
    width: 110px;
  }
  .first_title {
    font-size: 20px;
    text-align: center;
  }
  .qr-div{
    text-align: center;
  }
  .qr{
    width: 50%;
  }
  .market{
    width: 45%;
    height: 50px;
  }
  .numbers{
    /*margin-left: 12px;*/
    font-size: 43px;
    /*margin-right: 0px;*/
  }
  .mr-7{
    margin-right: 15px !important;
  }
  .title{
    font-size: 15px;
  }

  .mob_img_1{
    margin-left: 0 !important;
  }
  .for_center{
    text-align: center;
  }
  .margin{
    margin-left: 2px !important;
  }
  .margin_top{
    margin-top: 55px;
  }
  .question{
    width: 100%;
  }
  .question_title{
    font-size: 21px;
  }
  .cl4{
    padding-right: 0px !important;
  }
  .description{
    font-size: 14px;
    text-align: center;
  }
  .description > p{
    padding: 0px !important;
  }
  .speak,.finish{
    font-size: 12px;
  }
  .input::placeholder{
    font-size: 8px;
  }
  .client-submit-button,.submit-button{
    font-size: 8px;
  }
  .client {
    background-size: 93%;
  }

}
